<template>
    <main class="my-leads">
		
		<section class="content-my-leads">
			<div class="content-my-leads-itens">
				<div class="content-my-leads-itens-bg">
					<div class="container">
						<div class="content-my-leads-itens-header">
							<div class="content-my-leads-itens-header-div">
								<router-link class="btn-return" to="/">
									<svg xmlns="http://www.w3.org/2000/svg" width="9.586" height="10.582" viewBox="0 0 9.586 10.582"><path d="M-3.006-7.547,4.33-4.582v2.3L-5.256-6.727V-8.437l9.586-4.43v2.3Z" transform="translate(5.256 12.867)"/></svg>
									<p>voltar</p>
								</router-link>
								<h1 class="title">
									As suas oportunidades
								</h1>
							</div>
							<div class="content-my-leads-itens-header-element">
								<div class="content-text">
									<p>submetidas 
										<span v-if="Object.keys(total).length">{{total.total}}</span>
										<span v-else>0</span>
									</p>
								</div>
								<div class="div-border"></div>
								<div class="content-text">
									<p>aprovadas
										<span v-if="Object.keys(total).length">{{ total.total_approved ? total.total_approved : 0 }}</span>
										<span v-else>0</span>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="content-my-leads-itens-body">
					<div class="container">
						<div class="content-table">
							<table class="content-table-element">
								<tr>
									<th>Nome da lead</th>
									<th>Nome da campanha</th>
									<th>Data submissão</th>
									<th>Estado</th>
								</tr>
								
								<template v-if="Object.keys(leads).length">
									<tr v-for="lead in leads.data" :key="lead.id">
										<td>{{lead.name}}</td>
										<td><router-link
												v-if="lead.campaign" 
												:to="{name: 'campaign', params: {slug: lead.campaign.slug}}">
												{{lead.campaign.name}}</router-link>
											<span v-else>- campanha não encontrada -</span></td>
										<td>{{lead.created_at|moment('DD/MM/YYYY')}}</td>
										<td class="aproved" :style="{color: lead.status.html_class}">{{lead.status.name}}</td>
									</tr>
								</template>				
							</table>
							<!--  -->								
							<div v-if="Object.keys(leads).length && leads.last_page > 1" class="col-sm-12 mt-5" align="center">
								<nav aria-label="Page navigation example">
									<ul class="pagination">
										<li class="page-item">
											<a class="page-link"
											  :class="{
												  isDisabled: (!(leads.current_page > 1))
											  }"
											  @click="setPage(leads.current_page - 1)">Voltar</a>											  
										</li>

										<template v-for="page in leads.last_page">
											<li class="page-item" :key="page"
											:class="{active: (leads.current_page == page)}">
												<a class="page-link" @click="setPage(page)">{{page}}</a>
											</li>
										</template>										
										
										<li class="page-item">
											<a class="page-link"
											  :class="{
												  isDisabled: ((leads.current_page == leads.last_page))
											  }" 
											  @click="setPage(leads.current_page + 1)">Avançar</a>
										</li>
									</ul>
								</nav>
							</div>
							<!--  -->
						</div>						
					</div>
				</div>
			</div>
		</section>

	</main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';

export default {
    data(){
        return{
            leads : {}, 
			total: {},
			page: 1,           
        }
    },    
    methods:{
        ...mapActions('App', ['loading']),
		async setPage(page){
			this.page = page;

			await this.getList();
		},
        async getList(){
            this.loading(true);
            await axios({
                method: 'GET',
                url: '/api/v1/campaign/my-leads',
                params: {
                   page: this.page
                }             
            }).then(response => response.data.data)
            .then((data) => {  
               this.loading(false); 				   	
               this.leads = data.leads;      
			   this.total = data.total;         
            })          
        },        
    },
    async mounted(){        		
        await this.getList();
    }
}
</script>
<style scoped>
.content-my-leads-itens-bg{
	background-image: url('~@/assets/img/leads/banner_int.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.content-table-element tr td a{
	color: #707070;
	font-size: 18px;
	font-family: 'Font Regular';

}
.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}
</style>